import Vue from 'vue'
import App from './App.vue'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import router from './router'
import axios from 'axios'
import APlayer from '@moefe/vue-aplayer';

import '@/assets/font.css';
import 'lib-flexible/flexible'

Vue.use(iView,axios);
Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png',
  productionTip: true,
});

new Vue({
  router,
  axios,
  render: h => h(App),
}).$mount('#app')
