import Vue from 'vue'
import VueRouter from 'vue-router'
const home = resolve => require(['../views/home.vue'], resolve); //首页  
const page = resolve => require(['../views/page.vue'], resolve); //首页  
const login = resolve => require(['../views/login.vue'], resolve); //首页  
const father = resolve => require(['../views/father.vue'], resolve);   
const topicList = resolve => require(['../views/topicList.vue'], resolve);  
const wordPage = resolve => require(['../views/wordPage.vue'], resolve);  
const answerPage = resolve => require(['../views/answerPage.vue'], resolve); 
const studyPage = resolve => require(['../views/studyPage.vue'], resolve); 
const PoemPage = resolve => require(['../views/PoemPage.vue'], resolve); 



Vue.use(VueRouter)

const routes = [ 
  {
    path: '/',
    redirect: '/topicList',
    component: topicList,
    meta:{
      title: '投屏列表',
    }

  },
  {
    path: '/report/pdf',
    name: 'home',
    component: home,
  },
  
  {
    path: '/report/img',
    name: 'page',
    component: page,
  },
 
  
  {
    path: '/pinPdf',
    name: 'pinPdf',
    component: father,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{
      title: '看拼音写词语投屏教学',
    }
  },
  {
    path: '/topicList',
    name: 'topicList',
    component: topicList,
    meta:{
      title: '投屏列表',
    }
  },
  {
    path: '/wordPage',
    name: 'wordPage',
    component: wordPage,
    meta:{
      title: '投屏听读',
    }
  },
  {
    path: '/studyPage',
    name: 'studyPage',
    component: studyPage,
    meta:{
      title: '默写单详情',
    }
  },
  {
    path: '/answerPage',
    name: 'answerPage',
    component: answerPage,
    meta:{
      title: '语音报默',
    }
  },
  {
    path: '/PoemPage',
    name: 'PoemPage',
    component: PoemPage,
    meta:{
      title: '语音报默',
    }
  },
  
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  console.log(to,from)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router